import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { setOrdersFilter } from '../../actions'
import { Button, Popconfirm, Drawer, Tabs, Badge, message, Dropdown, Menu, Icon, Divider, notification, Alert, Timeline, Modal } from 'antd'
import Table from '../Table'
import moment from 'moment'
import gql from '../../api/gql'
import ButtonGroup from 'antd/lib/button/button-group'
import CustomerInfo from '../orders/CustomerInfo'
import Comments from '../orders/Comments'
import UploadDesigns from '../products/UploadDesigns'
import _ from 'lodash'
import SellerOrderFilter from './SellerOrderFilter'
import OrdersToolbar from '../orders/OrdersToolbar';
import FetchOrders from '../orders/FetchOrders';
import { ORDER_FIELD } from '../admin/Orders'
import styled from 'styled-components'
import UploadCustomDesigns from '../products/UploadCustomDesigns';
import SelectShippingCaseOrder from "../orders/SelectShippingCaseOrder";
import AddTracking from '../orders/AddTracking'

const Container = styled.div`
  .seller-order{
    display: flex;
    justify-content: space-between;
  }
  .toolbar {
    display: flex;
    justify-content: flex-end;
    .ant-btn.ant-btn-primary{
      margin-left: 5px;
    }
  }
  @media only screen and (max-width: 1140px) {
    .seller-order {
      display: flex;
      flex-direction: column-reverse;
    }
    .toolbar {
      display: flex;
      justify-content: flex-end;
      .ant-btn.ant-btn-primary{
        margin-left: 5px;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .seller-order {
      display: flex;
      flex-direction: column-reverse;
    }
    .toolbar {
      display: flex;
      justify-content: flex-end;
      .ant-btn.ant-btn-primary{
        margin-left: 5px;
      }
    }
  }
`

const PER_PAGE = 20
class Orders extends Component {
  state = {
    orders: [],
    aggs: [],
    selectedOrder: null,
    total: 0,
    filter: {
      ...this.props.filter,
      offset: 0,
      limit: PER_PAGE,
      aggs_by: 'status',
    },
    page: 1,
    loading: false,
    showUpdateCustomer: false,
    showOrderComments: false,
    showUploadDesign: false,
    showFetchOrders: false,
    selectedOrderCarrier: null,
    carriers: [],
    loadingMenuIcon: "down",
    initSetting: [],
    showAddTracking: false,
    addingTracking: false,
    disabledAddTracking: false,
    productTypes: [],
    orderHistory: [],
    showHistory: false
  }

  componentDidMount() {
    if (this.props.filter.site_ids) {
      this.loadOrders()
    }
    this.loadAppSetting()
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter.site_ids && prevProps.filter !== this.props.filter) {
      this.setState({
        page: 1,
        filter: { ...this.props.filter, offset: 0, limit: PER_PAGE, aggs_by: 'status' }
      }, () => this.loadOrders())
    }
  }

  loadOrders() {
    const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: this.state.filter
    }).then(res => {
      this.setState({
        orders: res.orders.hits || [],
        aggs: res.orders.aggs || [],
        total: res.orders.count,
        loading: false
      })
    })
  }

  loadOrderCarriers = (order_id) => {
    let query = `query order{
      order(id: ${order_id}){
        id,
        carrier{
          id
          name
        }
        variant{
          product_type_variant{
            id
            carrier_costs{
              id
              carrier_id
              cost
              default
            }
          }
          product_type{
            state
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
      }
    }`
    this.setState({ loadingMenuIcon: "loading" })
    gql.request(query).then(res => {
      let carriers = res.order.variant.product_type.product_type_carriers
      let variantCarrierCosts = res.order.variant.product_type_variant.carrier_costs
      carriers.map(cr => {
        const cost = variantCarrierCosts.filter(c => c.carrier_id === cr.carrier.id)
        if (cost && cost.length > 0) {
          cr.price = cost[0].cost
        }
        return cr
      })
      this.setState({
        carriers: carriers,
        loadingMenuIcon: "down"
      })
    }).catch(err => {
      this.setState({
        loadingMenuIcon: "down"
      })
      notification.error({
        message: _.get(err, '[0].message')
      })
    })
  }

  statusFilter = (status) => {
    const { setOrdersFilter } = this.props
    setOrdersFilter({ ...this.state.filter, status: (status === 'all' ? null : [status]) })
  }

  pauseOrResumeOrder = (id, status) => {
    const name = status === "pending" ? 'resumeOrder' : 'pauseOrder'
    const mutation = `mutation ${name}($order_id: Int!){
      ${name}(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, { order_id: id })
      .then(res => {
        message["success"](`Order has been ${status === "pending" ? "resumed " : "paused"}`)
        // this.setState({ loading: false }),
        // this.loadOrders()
        const orders = this.state.orders.map(el => {
          if (el.id === id) {
            if (name === "pauseOrder") {
              return res.pauseOrder
            } else {
              return res.resumeOrder
            }
          } else return el
        })
        this.setState({ orders, loading: false })
        this.loadOrders()
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"))
      })
  }

  cancelOrder = id => {
    const mutation = `mutation cancelOrder($order_id: Int!){
      cancelOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
    gql.request(mutation, { order_id: id })
      .then(res => {
        message["success"](`Order has been cancel`)
        const orders = this.state.orders.map(el => {
          if (el.id === id) {
            return res.cancelOrder
          } else return el
        })
        this.setState({ orders, loading: false })
        this.loadOrders()
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"))
      })
  }

  shopifyOrderGoInProduction = id => {
    const mutation = `mutation shopifyOrderGoInProduction($order_id: Int!){
      shopifyOrderGoInProduction(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
    gql.request(mutation, { order_id: id })
        .then(res => {
          message["success"](`Order review app Shopify`)
          const orders = this.state.orders.map(el => {
            if (el.id === id) {
              return res.shopifyOrderGoInProduction
            } else return el
          })
          this.setState({ orders, loading: false })
          this.loadOrders()
        })
        .catch(err => {
          message["error"](_.get(err, "[0].message"))
        })
  }

  starOrder = id => {
    const mutation = `mutation starOrder($order_id: Int!){
      starOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
    this.setState({loading: true })
    gql.request(mutation, { order_id: id })
        .then(res => {
          message["success"](`Order has been prioritized`)
          const orders = this.state.orders.map(el => {
            if (el.id === id) {
              return res.starOrder
            } else return el
          })
          this.setState({ orders, loading: false })
          this.loadOrders()
        })
        .catch(err => {
          message["error"](_.get(err, "[0].message"))
          this.setState({ loading: false })
        })
  }
  enterPayment = id => {
    const mutation = `mutation enterPayment($order_id: Int!){
      enterPayment(order_id: $order_id)
    }`
    this.setState({ loading: true })
    gql.request(mutation, { order_id: +id })
      .then(res => {
        this.setState({ loading: false })
        message["success"](`Order has been enter payment`)
        this.loadOrders()
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"))
      })
  }

  assignOrderToSupplier = (id) => (value) => {
    const mutation = `mutation AssignOrderSupplier($order_id: Int!, $supplier_id: Int!){
      AssignOrderSupplier(order_id: $order_id, supplier_id: $supplier_id){
        ${ORDER_FIELD}
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, { order_id: +id, supplier_id: +value }).then((res) => {
      const orders = this.state.orders.map(el => {
        if (el.id === id) {
          return res.AssignOrderSupplier
        } else return el
      })
      message["success"]('Assign supplier successfully')
      this.setState({ loading: false, orders: orders })
    })
  }

  assignOrderToCarrier = (carrier, id) => {
    const mutation = `mutation assignOrderCarrier($order_ids: [Int!], $carrier_id: Int!, $price: Float!){
      assignOrderCarrier(order_ids: $order_ids, carrier_id: $carrier_id, price: $price){
        ${ORDER_FIELD}
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, {
      order_ids: [id],
      carrier_id: carrier.carrier.id,
      price: carrier.price
    }).then(res => {
      const orders = this.state.orders.map(el => {
        if (el.id === id) {
          return res.assignOrderCarrier[0]
        } else return el
      })
      this.setState({ orders, loading: false })
    })
  }

  fetchOrdersHandle = () => {
    this.setState({ showFetchOrders: true })
  }
  loadAppSetting() {
    const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`
    const input = ["seller_message"]
    this.setState({ loading: true })
    gql.request(query, { input: input })
      .then(res => {
        this.setState({ initSetting: res.getAppSettings || [], loading: false })
      })
      .catch(err => {
        notification["error"]({
          message: 'Error',
          description: _.get(err, "[0].message")
        })
        this.setState({ loading: false })
      })
  }
  loadOrderHistory() {
    const query = `query GetOrderHistory($order_id: Int!){
      getOrderHistory(order_id: $order_id){
        id
        message
        user{
          id
          first_name
          last_name
        }
        order{
          id
        }
        type
        updated_at
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      order_id: this.state.selectedOrder.id
    }).then(res => {
      this.setState({
        orderHistory: res.getOrderHistory || [],
        loading: false
      })
    })
  }

  render() {
    const { orders, aggs, total, filter, loading, page, selectedOrder, showUpdateCustomer, showOrderComments, showUploadDesign, showFetchOrders, loadingMenuIcon, initSetting, orderHistory, showHistory, showAddTracking, addingTracking, disabledAddTracking } = this.state
    const setting = initSetting && initSetting.reduce((init, item) => ({ ...init, [item.name]: item.value }), {})
    const columns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: 100,
        render: (id, order) =>
          <div>
            <p>{`N${order.id.padStart(6, "0")}`}</p>
          </div>
      },
      {
        title: 'Origin Number / Origin ID',
        dataIndex: 'origin_id',
        key: 'origin_id',
        width: 150,
        render: (id, order) =>
          <div>
            <p>{order.name}</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{`${order.origin_id}`}</p>
          </div>
      },
      {
        title: 'Product / Variant',
        dataIndex: 'product',
        key: 'product',
        width: 350,
        render: (id, order) =>
          <div>
            <p>{`${order.product.title}`}</p>
            <div>{order.variant.product_type_variant.attributes && order.variant.product_type_variant.attributes.map((a, index) => {
              return (
                <Fragment key={index}>
                  <p style={{ marginBottom: 0, fontStyle: 'italic' }}><b>{a.slug}</b>: {a.option} {" "}</p>
                </Fragment>
              )
            })}
            </div>
          </div>
      },
      {
        title: 'Designs',
        dataIndex: 'id',
        key: 'id-design',
        width: 120,
        render: (id, order) => (!order.product.is_custom_design && order.variant.design_status) || (order.product.is_custom_design && order.design_status) ? <Button icon="check" type="primary" ghost onClick={e => {
          e.preventDefault()
          this.setState({
            showUploadDesign: true,
            selectedOrder: order
          })
        }}>View</Button> : <Button icon="warning" type="danger" ghost onClick={e => {
          e.preventDefault()
          this.setState({
            showUploadDesign: true,
            selectedOrder: order
          })
        }}>Upload</Button>
      },
      {
        title: 'QTY / COST',
        dataIndex: 'base_cost',
        key: 'base_cost',
        width: 150,
        render: (id, order) =>
          <div>
            <p>{`${order.quantity} x $${order.base_cost}`}</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{moment(order.created_at).format('MMM DD, YYYY')}</p>
          </div>
      },
      {
        title: 'Shipping Method',
        width: 220,
        key: 'carrier',
        render: (_, order) => {
          const carriers = this.state.carriers //order.variant.product_type.product_type_carriers
          const menu = (
            <Menu onClick={(item) => {
              let carrier = carriers.find(el => el.carrier.id == item.key)
              this.assignOrderToCarrier(carrier, order.id)
            }}>
              {
                carriers && carriers.map(el =>
                  <Menu.Item key={el.carrier ? el.carrier.id : 0} value={el.carrier}>{el.carrier ? el.carrier.name : ''} ({el.price > 0 ? '$' + el.price : 'Free'})</Menu.Item>
                )
              }
            </Menu>
          );
          if (!order.carrier) {
            return (
              <Dropdown overlay={menu} trigger={['click']} onVisibleChange={visible => {
                if (visible) {
                  this.setState({ carriers: [], selectedOrder: order })
                  this.loadOrderCarriers(order.id)
                }
              }}>
                <a className="ant-dropdown-link" href="/#" onClick={e => e.preventDefault()}>
                  Select Carrier <Icon type={loadingMenuIcon === "loading" && selectedOrder && order && selectedOrder.id === order.id ? "loading" : "down"} />
                </a>
              </Dropdown>
            )
          } else {
            return (
              <div>
                {
                  `${order.carrier.name} (${order.shipping_cost > 0 ? '$' + order.shipping_cost : "Free"}) `
                }
                {['pending', 'pending_design', 'processing'].includes(order.status)
                  && <Dropdown overlay={menu} trigger={['click']} onVisibleChange={visible => {
                    if (visible) {
                      this.setState({ carriers: [], selectedOrder: order })
                      this.loadOrderCarriers(order.id)
                    }
                  }}>
                    <a className="ant-dropdown-link" href="/#" onClick={e => e.preventDefault()}>
                      Edit <Icon type={loadingMenuIcon === "loading" && selectedOrder && order && selectedOrder.id === order.id ? "loading" : "down"} />
                    </a>
                  </Dropdown>
                }
              </div>
            )
          }
        }
      },
      {
        title: 'Supplier',
        width: 250,
        key: 'supplier',
        render: (_, order) => {
          if (order.supplier) {
            return (
              <div>
                {
                  `${order.supplier.first_name} ${order.supplier.last_name} `
                }
              </div>
            )
          }
        }
      },
      {
        title: 'Tracking code',
        width: 220,
        dataIndex: 'tracking',
        key: 'tracking',
        render: (tracking, order) => {
          if (tracking){
            let trackingUrl = order.carrier ? order.carrier.url : ""
            if (trackingUrl) {
              trackingUrl = trackingUrl.replace("TRACKING_NUMBER", tracking.tracking_code)
            }
            return (
                <Fragment>
                  <a href = {trackingUrl} target="_blank">{tracking.tracking_code} {" "}</a>
                  <span>
                <Button type="link" style={{ padding: 0 }} onClick={e => {
                  e.preventDefault()
                  this.setState({
                    carrier_id: order.carrier ? order.carrier.id : 0,
                    tracking_code: tracking.tracking_code,
                    showAddTracking: true,
                    selectedOrder: order
                  })
                }} >Edit
                </Button>
              </span>
                </Fragment>
            )
          }else{
            return (
                <Fragment>
                  {order.status === 'in_production' && <Button type="link" style={{ padding: 0 }} onClick={e => {
                    e.preventDefault()
                    this.setState({
                      showAddTracking: true,
                      selectedOrder: order
                    })
                  }}>Add Tracking</Button>}
                </Fragment>
            )
          }
        }
      },
      // {
      //   title: 'Seller',
      //   dataIndex: 'seller',
      //   key: 'site',
      //   width: 200,
      //   render: (id, order) =>
      //     <div>
      //       <p>{`${order.site && order.site.user ? order.site.user.first_name : ''} ${order.site && order.site.user ? order.site.user.last_name : ''}`}</p>
      //       <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{order.site.title}</p>
      //     </div>
      // },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        width: 250,
        render: (id, order) =>
          <div>
            <p>{`${order.customer.first_name} ${order.customer.last_name}`}</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{order.customer.email}</p>
          </div>
      },
      {
        title: 'Status',
        width: 150,
        fixed: 'right',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          const item = orderStatus.find(el => [status].includes(el.key))
          return item && <span style={{ color: `${item.color}` }}>{item.title}</span>
        }
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'actions',
        align: 'center',
        fixed: 'right',
        width: 200,
        render: (id, order) => <div>
          <ButtonGroup >
            {
              ['pending', 'pending_design', 'processing','in_production'].includes(order.status)
              &&
              <Fragment>
                <Popconfirm title="Skip order test app Shopify?" onConfirm={() => this.shopifyOrderGoInProduction(id)}>
                  <Button style={{ padding: 0, borderRadius: 4, color: "white",background:"#009688" }} size="small" icon="tool" />
                </Popconfirm>
                <Divider type="vertical" />
              </Fragment>
            }
            {
              (order.variant.product_type.state !== "paused" && ['pending', 'pending_design', 'processing'].includes(order.status)) && <>
                <Button style={{ padding: 0, borderRadius: 4, color: "#ffc000" }} size="small" icon={order.status !== 'pending' ? 'pause' : 'step-forward'} onClick={e => {
                  e.preventDefault()
                  this.pauseOrResumeOrder(id, order.status)
                }} />
                <Divider type="vertical" />
              </>
            }
            {
              ['pending', 'pending_design', 'processing','in_production'].includes(order.status)
              &&
              <Fragment>
                <Popconfirm title="Cancel this order?" onConfirm={() => this.cancelOrder(id)}>
                  <Button style={{ padding: 0, borderRadius: 4, color: "red" }} size="small" icon="close" />
                </Popconfirm>
                <Divider type="vertical" />
              </Fragment>
            }
            {/*
              ['pending', 'pending_design', 'processing','sup_failed','ready','sup_rejected'].includes(order.status)
              &&
              <Fragment>
                <Popconfirm title="Mark orders will be prioritized for production" onConfirm={() => this.starOrder(id)}>
                  { order.star_order == true && <Button disabled style={{ color: "#21c51f", borderRadius: 4, padding: 0 }} size="small" icon="star" />}
                  { order.star_order == false && <Button style={{ borderRadius: 4, padding: 0 }} size="small" icon="star" />}
                </Popconfirm>
                <Divider type="vertical" />
              </Fragment>
            }
            {
              ['fulfilled', 'in_production', 'sup_cancelled'].includes(order.status)
              &&
              <Fragment>
                { order.star_order == true && <Button disabled style={{ color: "#21c51f", borderRadius: 4, padding: 0 }} size="small" icon="star" />}
                { order.star_order == false && <Button disabled style={{ borderRadius: 4, padding: 0 }} size="small" icon="star" />}
                <Divider type="vertical" />
              </Fragment>
            */}
            {
              ['processing'].includes(order.status)
              &&
              <Fragment>
                <Popconfirm title="Enter payment for this order?" onConfirm={() => this.enterPayment(id)}>
                  <Button style={{ padding: 0, borderRadius: 4, color: "#ffc000" }} size="small" icon="dollar" />
                </Popconfirm>
                <Divider type="vertical" />
              </Fragment>
            }
            <Dropdown overlay={
              <Menu>
                <Menu.Item key="1">
                  <Button style={{ width: "100%" }} icon="info" onClick={e => {
                    e.preventDefault()
                    this.setState({
                      showUpdateCustomer: true,
                      selectedOrder: order
                    })
                  }}>Info</Button>
                </Menu.Item>
                {<Menu.Item key="3">
                  <Button style={{ width: "100%" }} icon="history" onClick={e => {
                    e.preventDefault()
                    this.setState({
                      showHistory: true,
                      selectedOrder: order
                    }, () => this.loadOrderHistory())
                  }}>History</Button>
                </Menu.Item>}
                {/* <Menu.Item key="3">
                  <Button icon="message" style={{width: "100%"}}
                   onClick={e => {
                    e.preventDefault()
                    this.setState({
                      showOrderComments: true,
                      selectedOrder: order
                    })
                  }}>Notes</Button>
                </Menu.Item> */}

                {
                  ['pending', 'pending_design', 'processing','sup_failed','ready','sup_rejected'].includes(order.status)
                  &&
                  <Menu.Item key="4">
                    <SelectShippingCaseOrder value={order.shipping_case ? order.shipping_case : "Basic"} orderId={order.id} shipping_case_skip={order.shipping_case_skip} />
                  </Menu.Item>
                }
                {
                  ['fulfilled', 'in_production', 'sup_cancelled'].includes(order.status)
                  &&
                  <Menu.Item key="5">
                    <Button style={{ width: "100%" }} icon="car" onClick={e => {
                      e.preventDefault()
                    }}>{order.shipping_case ? order.shipping_case : "Basic"}</Button>
                  </Menu.Item>
                }
              </Menu>}
              trigger={['click']}>
              <Icon type="dash" />
            </Dropdown>
          </ButtonGroup>
        </div>
      },
    ]
    const orderStatus = [
      {
        key: 'pending',
        title: 'Pending',
        color: '#ffd1d1',
      },
      {
        key: 'pending_design',
        title: 'Pending Designs',
        color: '#ffaeb4',
      },
      {
        key: 'processing',
        title: 'Processing',
        color: '#69ebf9',
      },
      {
        key: 'await',
        title: 'Await',
        color: 'blue',
      },
      {
        key: 'ready',
        title: 'Ready',
        color: '#66cccc',
      },
      {
        key: 'in_production',
        title: 'In Production',
        color: '#00cfff',
      },
      {
        key: 'sup_failed',
        title: 'Push Failed',
        color: 'red',
      },
      {
        key: 'sup_rejected',
        title: 'Supplier Rejected',
        color: 'red',
      },
      {
        key: 'sup_cancelled',
        title: 'Supplier Cancelled',
        color: 'red',
      },
      {
        key: 'fulfilled',
        title: 'Fulfilled',
        color: '#52c41a'
      },
      // {
      //   key: 'refunded',
      //   title: 'Refunded',
      //   color: '#ff5500'
      // },
      {
        key: 'cancelled',
        title: 'Cancelled',
        color: 'red'
      },
      {
        key: 'error',
        title: 'Error',
        color: 'red'
      },
    ]
    const tableWidth = _.sum(columns.map(c => c.width))
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState({
          page: page,
          filter: {
            ...filter,
            offset: (page - 1) * pageSize
          }
        }, () => this.loadOrders())
      }
    }
    const alert = (
      <div dangerouslySetInnerHTML={{ __html: setting['seller_message'] }} />
    )
    return (
      <Container>
        {setting && setting['seller_message'] && <Alert style={{ marginBottom: 20 }} type="error" message={alert} />}
        <div className="seller-order">
          <div style={{ display: "flex", alignItems: "center", width: "-webkit-fill-available" }}>
            <SellerOrderFilter {...this.props} />
          </div>
          <div style={{ textAlign: "right" }}>
            <div className="toolbar">
              <OrdersToolbar />
              {this.props.currentSite.type !== "offline" && <Button onClick={this.fetchOrdersHandle} type="primary" icon="cloud-download">Fetch Orders</Button>}
            </div>
          </div>
        </div>
        <Modal
            title="Add/Edit tracking code"
            visible={showAddTracking}
            destroyOnClose={true}
            okButtonProps={{ form: 'add-tracking', key: 'submit', htmlType: 'submit', disabled: disabledAddTracking }}
            onCancel={e => this.setState({ showAddTracking: false })}
            confirmLoading={addingTracking}
            onOk={e => this.setState({ addingTracking: true })}
        >
          {
            selectedOrder && <AddTracking onUnLoading={() => this.setState({addingTracking: false})} onChangeDisabledAddTracking={(v) => this.setState({disabledAddTracking: v})} order={selectedOrder} onClose={() => this.setState({ showAddTracking: false, addingTracking: false }, () => this.loadOrders())} />
          }
        </Modal>
        <Tabs onChange={this.statusFilter} style={{ marginBottom: 0 }} activeKey={filter.status ? filter.status[0] : 'all'}>
          <Tabs.TabPane tab={<Badge overflowCount={9999999} count={_.sum(aggs.map(agg => agg.doc_count))} style={{ background: '#fff', color: '#000', border: '1px solid #f5f5f5', right: -20 }}>All</Badge>} key="all"></Tabs.TabPane>
          {orderStatus.map(s => <Tabs.TabPane key={s.key} tab={<Badge overflowCount={9999999} count={_.sum(aggs.filter(agg => agg.key === s.key).map(a => a.doc_count))} style={{ backgroundColor: s.color }}>{s.title}</Badge>} />)}
        </Tabs>
        <Table scroll={{ x: tableWidth }} columns={columns} dataSource={orders} pagination={pagination} rowKey={({ id }) => id} loading={loading} />
        <Drawer
          title={`Update customer info #${selectedOrder ? selectedOrder.origin_id : ''}`}
          onClose={() => this.setState({
            showUpdateCustomer: false,
            selectedOrder: null
          })}
          width="500px"
          visible={showUpdateCustomer}>
          {selectedOrder && <CustomerInfo onClose={() => this.setState({
            showUpdateCustomer: false,
            selectedOrder: null
          })} onUpdate={() => this.setState({ showUpdateCustomer: false, selectedOrder: null }, () => this.loadOrders())} order={selectedOrder} />}
        </Drawer>
        <Drawer
          title={`Comments #${selectedOrder ? selectedOrder.origin_id : ''}`}
          onClose={() => this.setState({
            showOrderComments: false,
            selectedOrder: null
          })}
          width="500px"
          visible={showOrderComments}>
          {selectedOrder && <Comments order_id={selectedOrder.id} />}
        </Drawer>
        <Drawer
          width="800px"
          title="Upload Designs"
          onClose={e => this.setState({ selectedOrder: null, showUploadDesign: false })}
          visible={showUploadDesign}
        >
          {selectedOrder && selectedOrder.product.is_custom_design !== true && <UploadDesigns
            product_id={selectedOrder.product.id}
            order={selectedOrder}
            variant_type_id={selectedOrder.variant.product_type_variant.id}
            onClose={e => this.setState({ selectedOrder: null, showUploadDesign: false }, () => this.loadOrders())} />}
          {selectedOrder && selectedOrder.product.is_custom_design === true && <UploadCustomDesigns
            product_id={selectedOrder.product.id}
            variant_type_id={selectedOrder.variant.product_type_variant.id}
            order={selectedOrder}
            onClose={e => this.setState({ selectedOrder: null, showUploadDesign: false }, () => this.loadOrders())} />}
        </Drawer>
        {this.props.currentSite.type !== "offline" &&
          <Drawer
            width="500px"
            title="Fetch Orders"
            onClose={e => {
              this.setState({ showFetchOrders: false })
              this.loadOrders()
            }}
            visible={showFetchOrders}
          >
            <FetchOrders
              site_id={this.props.currentSite.id}
              onClose={e => this.setState({ showFetchOrders: false })} />
          </Drawer>
        }
        <Drawer
          width="800px"
          title="Order History"
          onClose={e => this.setState({ selectedOrder: null, showHistory: false })}
          visible={showHistory}
        >
          <Timeline>
            {orderHistory.map((his, index) => (
              <Timeline.Item color="orange"><div style={{ display: "flex" }}><div style={{ flex: "1 1" }}>{his.message}</div><div className="mr-7">{moment(his.updated_at).format('MMM DD, YYYY h:mm:ss a')}</div></div></Timeline.Item>
            ))}
          </Timeline>
        </Drawer>
      </Container>
    )
  }
}

export default connect(
  state => ({
    filter: state.orders.filter
  }), { setOrdersFilter }
)(Orders)