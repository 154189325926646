import React from 'react'
import { connect } from 'react-redux'
import { Select, Divider, Icon, Modal, Form, Input, message, notification } from 'antd'
import rest from '../../api/rest'
import _ from 'lodash'

class Collections extends React.Component{
  state = {
    loading: false,
    hasError: false,
    addCategory: false,
    categoryName: '',
    categories: [],
    value: []
  }

  componentDidMount() {
    this.getCategories()
  }

  getCategories = (page = 1) => {
    const {currentSite} = this.props
    rest.request("get", currentSite.id, `/admin/api/2024-07/custom_collections.json`).then(categories => {
      this.setState({
        categories: [...this.state.categories, ...categories.custom_collections]
      })
    }).catch(err => {
      notification['error']({
        message: _.get(err, 'response.data.errors')
      })
    })
  }

  addCategory = e => {
    e.preventDefault()
    const {categories, categoryName} = this.state
    const {currentSite} = this.props
    if (categoryName === '') {
      this.setState({hasError: true})
      message['error']('Please input category name')
      return
    }
    if (categories.find(cat => cat.name === categoryName)){
      message['warning']('Category already exists')
      return
    }
    this.setState({loading: true})
    rest.request("post", currentSite.id, "/admin/api/2024-07/custom_collections.json", {
      custom_collection: {title: categoryName}
    }).then(newCat => {
      this.setState({
        categories: [...this.state.categories, newCat.custom_collection],
        addCategory: false,
        loading: false,
        value: [...this.state.value, `${newCat.custom_collection.id}`]
      }, this.handleOnChange)
    })
  }

  handleOnChange = () => {
    const {onChange} = this.props
    if (typeof onChange === 'function'){
      onChange(this.state.value)
    }
  }

  render() {
    const {categories, loading, hasError, value} = this.state
    return (
      <div>
        <Select 
          value={value}
          onChange={value => {
            this.setState({value}, this.handleOnChange)
          }}
          placeholder="Select categories"
          showArrow={true}
          allowClear={true}
          style={{width: '100%'}} 
          mode="multiple" 
          optionFilterProp="children" 
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
							<div style={{padding: '4px 8px 8px', cursor: 'pointer'}}
								onMouseDown={e => e.preventDefault()}
								onClick={(e)=>{
									e.preventDefault()
									this.setState({addCategory: true})
								}}>
                <Icon type="plus" /> Add item
              </div>
            </div>
          )}>
          {categories.map(cat => <Select.Option key={cat.id}>{cat.title}</Select.Option>)}
        </Select>
        <Modal 
          visible={this.state.addCategory} 
          confirmLoading={loading}
          onCancel={()=>this.setState({addCategory: false})}
          onOk={this.addCategory}
          title="New Category">
            <Form.Item label="Category" className={hasError ? 'has-error' : ''}>
              <Input value={this.state.categoryName} onChange={e=>this.setState({hasError: false, categoryName: e.target.value})}/>
            </Form.Item>
        </Modal>
      </div>
    )
  }
}

export default connect(state => ({
  currentSite: state.app.currentSite
}))(Collections)