import rest from '../api/rest'
import _ from 'lodash'
import shortuuid from 'short-uuid'

export const Shopify = {
  async pushProduct(site_id, productTypes, product) {
    console.log("Starting pushProduct with site_id:", site_id);
    return new Promise(async (resolve, reject) => {
      try {
        let productAttributes = []
        let variantImages = []
        var _attributes = [].concat.apply([], product.variants.map(variant => variant.attributes))
        _attributes.forEach(attr => {
          let tmpAttr = productAttributes.find(at => at.slug === attr.slug) || { name: attr.name, slug: attr.slug, terms: [] }
          tmpAttr.terms = _.union([...tmpAttr.terms, attr.option])
          productAttributes = _.uniqBy([...productAttributes, tmpAttr], 'slug')
        })

        if (productTypes.length > 1) {
          let productTypeAttribute = {
            name: "Type",
            terms: productTypes.map(type => type.title)
          }
          productAttributes = [productTypeAttribute, ...productAttributes]
        }

        // Ensure tags is an array
        const productTags = Array.isArray(product.tags) ? product.tags :
            (typeof product.tags === 'string' ? product.tags.split(',') : []);

        let shopifyProduct = {
          product: {
            title: product.title,
            handle: product.permalink ? product.permalink : null,
            product_type: product.product_type || 'POD',
            body_html: product.description,
            published_scope: 'global',
            vendor: 'POD',
            tags: productTags,
            images: product.images.map(image => ({ src: image.url })),
            options: productAttributes.map(attr => ({
              name: attr.name
            })),
            metafields: [
              {
                namespace: 'pod',
                value_type: 'json_string',
                type: 'json_string',
                key: 'pod_product_types',
                value: JSON.stringify(productTypes.map(type => type.id.toString()))
              }
            ],
            variants: product.variants.map((variant, i) => {
              let options = {}
              productAttributes.forEach((attr, index) => {
                let existsAttr = variant.attributes.find(at => at.name === attr.name)
                if (existsAttr) {
                  options[`option${index + 1}`] = existsAttr.option
                } else {
                  if (attr.name === "Type" && productTypes.length > 1) {
                    const productType = productTypes.find(type => type.id === variant.product_type_id);
                    options[`option${index + 1}`] = productType ? productType.title : "";
                  } else {
                    options[`option${index + 1}`] = ""
                  }
                }
              })

              let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null

              // Lưu thông tin cần thiết cho inventory item sau này
              variantImages.push({
                position: i + 1,
                index: variantImageIndex,
                base_cost: variant.base_cost,
                weight: product.weight ? product.weight : null,
                weight_unit: product.weight ? product.weight_unit : null,
                sku: `${variant.sku}:${shortuuid.generate()}`
              })

              // Ensure price is not null
              let price = variant.sale_cost;
              if (price === null || price === undefined) {
                price = variant.retail_cost || 0;
              }

              // Không còn sử dụng các trường đã bị loại bỏ trong API 2024-07
              return {
                compare_at_price: variant.retail_cost,
                price: price,
                // Đã loại bỏ fulfillment_service, weight, weight_unit, sku
                ...options,
                metafields: [
                  {
                    namespace: 'pod',
                    value_type: 'integer',
                    type: 'integer',
                    key: 'pod_product_type_variant_id',
                    value: variant.id
                  }
                ]
              }
            })
          }
        }

        console.log("Sending product to Shopify API...");

        // Cập nhật endpoint API thành 2024-07
        const response = await rest.request('post', site_id, '/admin/api/2024-07/products.json', shopifyProduct);

        if (response.product && response.product.variants) {
          // Tạo mảng promises cho cả update ảnh và update inventory items
          const updatePromises = response.product.variants.map(async variant => {
            const promises = [];

            // Promise update ảnh
            let variantImageIndex = _.find(variantImages, { position: variant.position })
            if (variantImageIndex && variantImageIndex.index !== null) {
              promises.push(
                  rest.request("PUT", site_id, `/admin/api/2024-07/variants/${variant.id}.json`, {
                    variant: {
                      id: variant.id,
                      image_id: variantImageIndex.index !== null ? response.product.images[variantImageIndex.index].id : null
                    }
                  }).catch(err => {
                    console.error(`Error updating variant ${variant.id} image:`, err);
                    return null; // Don't fail the whole process
                  })
              );
            }

            const originalVariant = _.find(variantImages, { position: variant.position });
            promises.push(
                rest.request("PUT", site_id, `/admin/api/2024-07/inventory_items/${variant.inventory_item_id}.json`, {
                  inventory_item: {
                    id: variant.inventory_item_id,
                    cost: originalVariant.base_cost?.toString() || "0.00",
                    // Di chuyển các trường đã bị loại bỏ từ variant sang inventory_item
                    weight: originalVariant.weight,
                    weight_unit: originalVariant.weight_unit,
                    sku: originalVariant.sku,
                    tracked: false
                  }
                }).catch(err => {
                  console.error(`Error updating inventory item ${variant.inventory_item_id}:`, err);
                  return null; // Don't fail the whole process
                })
            );

            // Đợi cả hai promises hoàn thành cho mỗi variant
            try {
              await Promise.all(promises);
              console.log(`Variant ${variant.id} updated successfully`);
            } catch (err) {
              console.error(`Error updating variant ${variant.id}:`, err);
            }
          });

          // Đợi tất cả variants được update xong
          await Promise.all(updatePromises);
          console.log("All variants updated successfully");
        }

        resolve(response.product);
      } catch (err) {
        console.error("Error in pushProduct:", err);

        // Chi tiết hóa lỗi
        if (err.response && err.response.data) {
          console.error("API Error response:", err.response.data);
        }

        reject(err);
      }
    });
  },

  async mapProduct(site_id, origin_id, productTypes, variations) {
    // Tạm thời chưa cập nhật vì bạn đang sử dụng Promise.reject("Sorry")
    return Promise.reject("Sorry");
  }
}